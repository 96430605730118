import P from "./Paragraph"
import HeroImage from "./HeroImage"

const CaseHero = (props) => {
  return (
    <div className="mt-20 text-black">
      {/* Hero image */}
      <HeroImage
        thumb={props.thumb}
        title={props.children}
        layout={props.layout}
      ></HeroImage>
      {/* Project Details */}
      <div className="mt-16 flex justify-center">
        <div className=" md:w-4/5 flex md:flex-nowrap flex-wrap md:gap-24 gap-10">
          <div className="md:w-1/2 w-full">
            <p className="font-bold font-neue mb-2">{props.case}</p>
            <P>{props.des}</P>
          </div>
          <div className="md:w-2/5 w-full">
            <hr className="mb-6 md:hidden" />
            <div className="flex md:flex-row flex-col md:gap-8 gap-2">
              <p className="font-bold font-neue md:mb-2 md:w-[20%] md:text-right">
                ROLE
              </p>
              <div className="w-4/5">
                {props.role.map((item, index) => (
                  <P key={index}>{item}</P>
                ))}
              </div>
            </div>
            <hr className="mt-6" />
            <div className="flex md:flex-row flex-col md:gap-8 gap-2 mt-8">
              <p className="font-bold font-neue md:mb-2 md:w-[20%] md:text-right">
                TEAM
              </p>
              <div className="w-4/5">
                {props.team.map((item, index) => (
                  <P key={index}>{item}</P>
                ))}
              </div>
            </div>
            <hr className="mt-6" />
            {props.company && (
              <P className="mt-6 opacity-60">{props.company}</P>
            )}
            {props.link && (
              <P className="mt-6 opacity-60 underline underline-offset-2">
                <a href={`https://${props.link}`} target="_blank">
                  {props.link}
                </a>
              </P>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CaseHero
