// custom components
import CaseHero from "../component/CaseHero"
import H from "../component/Title"
import P from "../component/Paragraph"
import Nextcase from "../component/Nextcase"

// gsap
import { gsap, Power2 } from "gsap"
import { ScrollTrigger } from "gsap/all"
import { useRef, useEffect } from "react"

// static images
import tt_thumbnail_page from "../static/tt_thumbnail_page.webp"
import tt_old1 from "../static/tt_old1.webp"
import tt_old2 from "../static/tt_old2.webp"
import tanmay from "../static/tanmay.webp"
import beer from "../static/beer.webp"
import ankur from "../static/ankur.webp"
import stack1 from "../static/stack1.webp"
import stack2 from "../static/stack2.webp"
import stack3 from "../static/stack3.webp"
import gallery1 from "../static/gallery1.webp"
import gallery2 from "../static/gallery2.webp"
import vid1 from "../static/Tickertape.mp4"
import explore1 from "../static/explore1.webp"
import explore2 from "../static/explore2.webp"

gsap.registerPlugin(ScrollTrigger)

const Tickertape = () => {
  let oldRef = useRef(null)
  let stack1Ref = useRef(null)
  let stack2Ref = useRef(null)
  let stack3Ref = useRef(null)
  let containerRef = useRef(null)

  useEffect(() => {
    const oldEl = oldRef.current
    const stack1El = stack1Ref.current
    const stack2El = stack2Ref.current
    const stack3El = stack3Ref.current
    const containerEl = containerRef.current

    gsap.from(containerEl, {
      opacity: 0.8,
      duration: 0.7,
      ease: Power2.easeOut,
      y: 50,
    })

    // gsap.fromTo(
    //   oldEl,
    //   {
    //     opacity: 0,
    //   },
    //   {
    //     opacity: 1,
    //     x: -200,
    //     duration: 1,
    //     ease: Power2.easeOut,
    //     scrollTrigger: {
    //       trigger: oldEl,
    //       start: "top 90%",
    //       end: "top 50%",
    //       scrub: true,
    //     },
    //   }
    // )

    // gsap.fromTo(
    //   stack1El,
    //   { x: -400 },
    //   {
    //     x: -200,
    //     duration: 1,

    //     scrollTrigger: {
    //       trigger: stack1El,
    //       start: "top 100%",
    //       end: "top 0%",
    //       scrub: true,
    //       markers: true,
    //     },
    //   }
    // )

    // gsap.fromTo(
    //   stack2El,
    //   { x: 0 },
    //   {
    //     x: 0,
    //     duration: 1,

    //     scrollTrigger: {
    //       trigger: stack2El,
    //       start: "top 100%",
    //       end: "top 0%",
    //       scrub: true,
    //     },
    //   }
    // )

    // gsap.fromTo(
    //   stack3El,
    //   { x: -400 },
    //   {
    //     x: -200,
    //     duration: 1,

    //     scrollTrigger: {
    //       trigger: stack3El,
    //       start: "top 100%",
    //       end: "top 0%",
    //       scrub: true,
    //     },
    //   }
    // )
  }, [])

  return (
    <div ref={containerRef}>
      <CaseHero
        thumb={tt_thumbnail_page}
        case="REVAMPING TICKERTAPE HOMEPAGE"
        des="Tickertape is your all-in-one screening, trading & investment platform for stocks, mutual funds, ETFs and indices for Indian share market.
        The existing homepage of Tickertape was obsolete both from a design view and from the perspective of change in our mission. I led the design to revamp the homepage for Tickertape."
        role={[
          "Lead design",
          "Visual design",
          "Story-telling",
          "Design system",
        ]}
        team={[
          "Harsha Garlapati - PM",
          "Mohit Mandal - Developement",
          "Sumit kumar - Design",
        ]}
        link="www.tickertape.in"
      >
        Tickertape
        <span className="font-laica font-normal"> Homepage</span>
      </CaseHero>

      {/* Container */}
      <div className="flex flex-col md:gap-32 gap-32 md:mt-40 mt-32">
        {/* Background */}
        <div className="flex justify-center">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              BACKGROUND
            </p>
            <H>
              Why revamp <span className="font-laica">homepage</span>
            </H>
            <div className="md:w-2/5 mt-6 flex flex-col gap-3">
              <P>
                When Tickertape started, it was mainly used by people who were
                good with technical stuff and wanted to use advanced tools for
                investing and analyzing. But as more types of people started
                using it, we needed to change how Tickertape looked and felt. We
                wanted to make it more interesting and easy to understand for a
                bigger group of people, especially young individuals who are new
                to investing. To do this, we decided to start the change on our
                homepage, which is like the face of our website.
              </P>
            </div>
            <div className="flex gap-8 mt-12 items-start" ref={oldRef}>
              <img src={tt_old1} alt="" className="max-w-[70%]" />
              <img src={tt_old2} alt="" className="max-w-[70%]" />
            </div>
            <div className="md:w-2/5 mt-12 flex flex-col gap-3">
              <P>
                At first, our website's front page looked neat and serious,
                focusing a lot on technical things, which matched what
                Tickertape was first meant for. But things were changing. Many
                people were leaving the site quickly and not signing up as much
                as we wanted. We added new things to help people who didn't know
                much about investing. So, we decided to change how our front
                page looked to show these new tools.
              </P>
            </div>
          </div>
        </div>

        {/* User personas and design strategy */}
        <div>
          <div className="flex justify-center">
            <div className="md:w-4/5">
              <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
                RESEARCH
              </p>
              <H>
                User personas and{" "}
                <span className="font-laica">design strategy</span>
              </H>
              <div className="md:w-2/5 mt-6 flex flex-col gap-3">
                <P>
                  While revamping Tickertape's homepage, our primary aim was to
                  cater to a spectrum of users, from novices to intermediate
                  level investors.
                  <br />
                  <br />
                  To do this effectively, we centered our design strategy around
                  three distinct user personas: Early Tanmay Bhat, Beer Biceps,
                  and Warikoo.
                </P>
              </div>

              {/* Tanmay */}
              <div className="md:mt-16 mt-10 flex flex-col gap-3">
                <div className="flex md:flex-row flex-col md:gap-16 items-start">
                  <div className="flex flex-col gap-6 md:w-3/5">
                    <h2 className="font-beausite text-medium text-2xl">
                      Early Tanmay Bhat
                    </h2>
                    <div className="flex gap-6 items-start">
                      <img src={tanmay} alt="" className="w-1/3" />
                      <P>
                        The early-stage investor, keen to learn but unsure where
                        to start. They are overwhelmed by information, lack
                        clarity on investment structures, and are yet to
                        understand their risk appetite. They represent a
                        significant section of our targeted user base - the
                        young, curious investor, eager to start but needing a
                        guiding hand.
                      </P>
                    </div>
                  </div>
                  <div className="md:mt-12 mt-4 md:w-2/5">
                    <h3 className="font-beausite text-medium text-xl">
                      Strategy{" "}
                    </h3>
                    <P className="mt-3">
                      For this persona, our main goal was to create a friendly,
                      easy-to-understand environment. We focused on the "Learn"
                      pillar of Tickertape, using clear, jargon-free language to
                      explain investment basics and structured the homepage to
                      highlight educational resources.
                    </P>
                  </div>
                </div>
              </div>

              {/* Beer biceps */}
              <div className="md:mt-16 mt-10 flex flex-col gap-3">
                <div className="flex md:flex-row flex-col md:gap-16 items-start">
                  <div className="flex flex-col gap-6 md:w-3/5">
                    <h2 className="font-beausite text-medium text-2xl">
                      Beer Biceps
                    </h2>
                    <div className="flex gap-6 items-start">
                      <img src={beer} alt="" className="w-1/3" />
                      <P>
                        The beginner investor, already dipping their toes in the
                        investment world, but cautious and reliant on advice.
                        They have a vague investment plan and tend to gravitate
                        towards low-risk stocks, partly due to a lack of
                        knowledge about analysis.
                      </P>
                    </div>
                  </div>
                  <div className="md:mt-12 mt-4 md:w-2/5">
                    <h3 className="font-beausite text-medium text-xl">
                      Strategy{" "}
                    </h3>
                    <P className="mt-3">
                      To help Beer Biceps, we aimed to build confidence through
                      knowledge. We put a spotlight on low-risk investment
                      opportunities and simplified our analysis tools to make
                      them more accessible. We also highlighted the community
                      aspect of Tickertape, where they can learn from others'
                      experiences.
                    </P>
                  </div>
                </div>
              </div>

              {/* Warikoo */}
              <div className="md:mt-16 mt-10 flex flex-col gap-3">
                <div className="flex md:flex-row flex-col md:gap-16 items-start">
                  <div className="flex flex-col gap-6 md:w-3/5">
                    <h2 className="font-beausite text-medium text-2xl">
                      Ankur Warikoo
                    </h2>
                    <div className="flex gap-6 items-start">
                      <img src={ankur} alt="" className="w-1/3" />
                      <P>
                        The moderately confident investor, knowledgeable about
                        stocks, and able to explore Mutual Funds. However, they
                        may struggle with data clutter, limited research time,
                        and dependency on external advice, which often leads to
                        confusion or delayed decisions.
                      </P>
                    </div>
                  </div>
                  <div className="md:mt-12 mt-4 md:w-2/5">
                    <h3 className="font-beausite text-medium text-xl">
                      Strategy{" "}
                    </h3>
                    <P className="mt-3">
                      For Warikoo, our focus was on streamlining information and
                      providing tools for efficient research. We emphasized
                      Tickertape's "Analyse" pillar, offered easily digestible
                      financial data, and provided platforms for cross-checking
                      advice from influencers.
                    </P>
                  </div>
                </div>
              </div>

              <div className="flex justify-start">
                <P className="md:mt-20 mt-8 md:w-2/5">
                  Through this user-centric approach, we sought to craft a
                  Tickertape experience that was educational for the beginners,
                  empowering for the cautious investors, and efficient for the
                  intermediates, thereby catering to a broad user base while
                  respecting their unique investment journeys.
                </P>
              </div>
            </div>
          </div>
        </div>

        {/* Designing for the modern investor */}
        <div className="flex justify-center">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              IDEATION AND WIREFRAMING
            </p>
            <H>
              Designing for the{" "}
              <span className="font-laica">modern investor</span>
            </H>
            <div className="flex md:flex-row flex-col gap-6 justify-between items-start">
              <div className="md:w-2/5 w-full md:mt-6 mt-4 flex flex-col gap-2">
                <P>
                  With the goal of making the Tickertape experience engaging and
                  accessible, we needed a narrative that appealed to the young,
                  enthusiastic investor looking to build wealth through informed
                  decisions. We wanted them to:
                </P>
                <ul className="list-disc pl-4">
                  <li className="mt-2">
                    <P>
                      Learn about investing in simple, understandable language,{" "}
                    </P>
                  </li>
                  <li className="mt-2">
                    <P>
                      Have the right tools to make informed investment
                      decisions, and
                    </P>
                  </li>
                  <li className="mt-2">
                    <P>
                      Feel part of a community where they can discuss, learn,
                      and grow.
                    </P>
                  </li>
                </ul>
                <P className="mt-4">
                  Bearing these user goals in mind, we dived into crafting a new
                  design. From the hero section that promised "Financial freedom
                  begins here" to a granular overview of our product's
                  offerings, we set to balance simplicity with the promise of
                  potential growth.
                </P>
              </div>
            </div>

            {/* stack */}
            <div className="flex flex-col md:gap-8 gap-4 md:mt-12 mt-8 w-[160%] max-w-[1000%] md:ml-[-120px]">
              <img src={stack1} alt="" className="" ref={stack1Ref} />
              <img src={stack2} alt="" className="" ref={stack2Ref} />
              <img src={stack3} alt="" className="" ref={stack3Ref} />
            </div>
            <div className="md:w-2/5 w-full md:mt-12 mt-8">
              <P>
                Our task was to take complex financial concepts and distill them
                into visually engaging, easy-to-understand narratives. We ran
                through 4-5 iterations for each section of the page, critiquing,
                refining, and honing until we landed on a layout that best
                served our users and the Tickertape story.
              </P>
            </div>
          </div>
        </div>

        {/* Exploring visuals */}
        <div className="flex justify-center">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              INITIAL VISUAL DIRECTION
            </p>
            <H>
              Exploring
              <span className="font-laica"> visuals</span>
            </H>

            <div className="md:w-2/5 w-full md:mt-6 mt-4">
              <P>
                Finding the right visual direction was a process of
                experimentation and refinement. We wanted our design to resonate
                with our audience - colorful yet formal, fun yet controlled,
                approachable yet reliable.
              </P>
            </div>

            {/* Gallery */}
            <div className="flex justify-center md:mt-12 mt-8">
              <div className="flex md:flex-row flex-col gap-8">
                <img src={gallery1} alt="" className="md:w-[50%] w-full" />
                <img src={gallery2} alt="" className="md:w-[50%] w-full" />
              </div>
            </div>
          </div>
        </div>

        {/* Settling on our homepage's new look */}
        <div className="flex justify-center">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              FINAL DIRECTION AND INTERACTIONS
            </p>
            <H>
              Settling on our homepage's
              <span className="font-laica"> new look</span>
            </H>
            <div className="md:w-2/5 w-full md:mt-6 mt-4">
              <P>
                After critiquing several versions, we finally aligned on a
                design that perfectly mirrored our brand's ethos.
              </P>
            </div>
            <video
              src={vid1}
              autoPlay
              muted
              controls
              className="md:mt-12 mt-8 rounded-xl border-solid border-slate-100 border-2"
            ></video>
            <h2 className="font-beausite font-medium text-2xl md:mt-24 mt-16">
              UI explorations
            </h2>

            <div className="flex md:flex-row flex-col gap-12 mt-12">
              <img src={explore1} alt="" className="md:w-1/2" />
              <img src={explore2} alt="" className="md:w-1/2" />
            </div>
          </div>
        </div>

        {/* Outcome */}
        <div className="flex justify-center">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              RESULTS
            </p>
            <H>
              Homepage redesign
              <span className="font-laica"> outcome</span>
            </H>
            <div className="md:w-2/5 w-full md:mt-6 mt-4">
              <P>
                In an effort to enhance our brand, we decided to overhaul the
                Tickertape homepage, leading to several changes. The rate at
                which users left the page decreased, sign-ups increased, and
                user retention improved. There was also a rise in app downloads,
                signaling greater user participation. This process highlighted
                the importance of adapting and growing. By considering user
                input, we improved our homepage. The new layout is more
                user-centered and mirrors the updated Tickertape - a platform
                open to everyone for learning and investing, regardless of their
                familiarity with the subject.
              </P>
            </div>
          </div>
        </div>

        {/* Next case study */}
        <Nextcase to="/wise">
          Wise <span className="font-laica">app</span>
        </Nextcase>
      </div>
    </div>
  )
}

export default Tickertape
