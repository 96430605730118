import H from "./Title"
import { Link } from "react-router-dom"

const Nextcase = (props) => {
  return (
    <div className="flex flex-col items-center my-16">
      <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
        NEXT CASE STUDY
      </p>
      <H>
        <Link
          to={props.to}
          className="underline decoration-2 underline-offset-4"
        >
          {props.children}
        </Link>
      </H>
    </div>
  )
}

export default Nextcase
