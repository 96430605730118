// Components
import CaseHero from "../component/CaseHero"
import P from "../component/Paragraph"
import H from "../component/Title"
import Nextcase from "../component/Nextcase"

// gsap
import { gsap, Power2 } from "gsap"
import { useRef, useEffect } from "react"

// Static
import Classroom1 from "../static/Classroom-1.webp"
import Classroom2 from "../static/Classroom-2.webp"
import Classroom3 from "../static/Classroom-3.webp"
import wise_hero from "../static/wise_hero.webp"
import wise_old1 from "../static/wise_old1.webp"
import wise_old2 from "../static/wise_old2.webp"
import wise_old3 from "../static/wise_old3.webp"
import homepage from "../static/Homepage.webp"
import discussions from "../static/Discussions.webp"
import resource1 from "../static/Resources_1.webp"
import resource2 from "../static/Resources_2.webp"
import resource3 from "../static/Resources_3.webp"
import assessment1 from "../static/Assessments-1.webp"
import assessment2 from "../static/Assessments-2.webp"
import assessment3 from "../static/Assessments-3.webp"
import wise1 from "../static/wise_1.webp"
import wise2 from "../static/wise_2.webp"
import wise3 from "../static/wise_3.webp"
import wise4 from "../static/wise_4.webp"
import wise5 from "../static/wise_5.webp"
import wise6 from "../static/wise_6.webp"
import wise7 from "../static/wise_7.webp"
import wise8 from "../static/wise_8.webp"

const Wise = () => {
  let containerRef = useRef(null)

  useEffect(() => {
    const containerEl = containerRef.current

    gsap.from(containerEl, {
      opacity: 0.8,
      duration: 0.7,
      ease: Power2.easeOut,
      y: 50,
    })
  }, [])

  return (
    <div className="" ref={containerRef}>
      <CaseHero
        thumb={wise_hero}
        case="WISE APP"
        des="Wise App is a one stop solution for online teaching. Wise allows teachers to schedule and conduct live classes with a single click of a button. Additionally, teachers can collect fees, send assignments, conduct tests, facilitate discussions and share study material."
        role={["End to end design", "Requirements", "Prototyping"]}
        team={["Nadeem Ansari - iOS developer", "Sumit kumar - Design"]}
        link="www.wise.live"
        layout="row"
      >
        Wise <span className="font-laica font-normal"> app</span>
      </CaseHero>
      <div className="flex flex-col md:gap-32 gap-32 md:mt-40 mt-32">
        {/* Learning management system */}
        <div className="flex justify-center">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              BACKGROUND
            </p>
            <H>
              Learning management <span className="font-laica">system</span>
            </H>
            <div className="md:w-2/5 mt-6 flex flex-col gap-3">
              <P>
                Wise is a LMS. It provide an online environment where
                instructors can create, organize, and distribute learning
                materials, as well as interact with students. Some of the
                features it offers are:
              </P>
              <div className="flex flex-col gap-4">
                <P>
                  <span className="font-medium">
                    Streamlined Course Management:
                  </span>{" "}
                  Wise offer a centralized platform for organizing and managing
                  course materials.
                </P>
                <P>
                  <span className="font-medium">Attendance management:</span> It
                  simplifies the process, helps identify absenteeism, and
                  ensures accurate attendance records for raising invoices &
                  other admin purposes.
                </P>
                <P>
                  <span className="font-medium">Efficient Communication:</span>{" "}
                  Wise provides a forum to interact with students, address
                  queries, provide feedback, and foster collaboration.
                </P>
                <P>
                  <span className="font-medium">
                    Assessment and Progress Tracking:
                  </span>{" "}
                  Wise enable instructors to create and administer assessments &
                  tests, track student progress, and provide timely feedback.
                </P>
              </div>
            </div>
          </div>
        </div>
        {/* Why revamp Wise iOS app */}
        <div className=" flex justify-center">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              BACKGROUND
            </p>
            <H>
              Why revamp Wise <span className="font-laica">iOS app</span>
            </H>
            <div className="md:w-2/5 mt-6 flex flex-col gap-3">
              <P>
                We noticed some issues with our Wise iOS app - it wasn't
                performing well, users weren't happy, and the design felt
                outdated. Built with React Native, the app struggled with Zoom
                API integration, leading to many bugs. These issues, coupled
                with the outdated design, resulted in a below-average App Store
                rating of 3.5.
                <br />
                <br />
                We decided to fix these problems. Our goals? Improve our App
                Store rating to 4.5, make users happier, reduce bugs, and help
                our app grow. We also wanted to bring our iOS app up to speed
                with our Android version.
                <br />
                <br />
                To start, we took a good look at our current designs, identified
                problems, and mapped out how to make them better. Our focus was
                on making the app look fresh, feel more responsive, and be less
                buggy.
              </P>
            </div>

            <div className="bg-[#EAEDF5] flex md:justify-center gap-8 md:rounded-3xl p-8 mt-10 md:mx-0 -mx-8 overflow-scroll">
              <img src={wise_old1} alt="" className="md:w-[30%] w-2/3" />
              <img src={wise_old2} alt="" className="md:w-[30%] w-2/3" />
              <img src={wise_old3} alt="" className="md:w-[30%] w-2/3" />
            </div>
          </div>
        </div>
        {/* Towards a brand new interface */}
        <div className="flex justify-center">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              VISUAL DESIGN
            </p>
            <H>
              Towards a brand new <span className="font-laica">interface</span>
            </H>
            <div className="md:w-2/5 mt-6 flex flex-col gap-3">
              <P>
                As we started the redesign, we stuck to two guiding principles:
                Delightful and Simple. We wanted our app to not just function
                well but also be a joy to use. Simplicity was crucial to make
                sure that our users could easily navigate the app and find what
                they needed. We looked at each section with a critical eye.
              </P>
            </div>
            {/* Classroom */}
            <div className="">
              <h2 className="font-beausite text-2xl mt-10">Classroom</h2>
              <P className="mt-3 md:w-2/5">
                We revamped the navigation, ditching the swiping for a clear,
                concise list view, making it easy for users to see all options
                at once. We added delightful icons to aid recognition.
              </P>
              <div className="bg-[#EAEDF5] flex md:justify-center gap-8 md:rounded-3xl p-8 mt-10 md:mx-0 -mx-8 overflow-scroll">
                <img src={Classroom1} alt="" className="md:w-[30%] w-2/3" />
                <img src={Classroom2} alt="" className="md:w-[30%] w-2/3" />
                <img src={Classroom3} alt="" className="md:w-[30%] w-2/3" />
              </div>
            </div>
            {/* Homepage */}
            <div className="flex md:flex-row flex-col justify-start md:gap-32 gap-16 md:mt-8 mt-8">
              <div className="md:w-2/5">
                <h2 className="font-beausite text-2xl mt-10">Homepage</h2>
                <P className="mt-3">
                  We introduced classroom thumbnails for classrooms. The new
                  clean layout was easier on the eyes and made it easy to find
                  relevant information.
                </P>
                <div className="bg-[#EAEDF5] flex justify-center gap-8 md:rounded-3xl p-12 mt-8 -mx-8 md:mx-0">
                  <img src={homepage} alt="" className="md:w-full w-2/3" />
                </div>
              </div>
              {/* Discussion */}
              <div className="md:w-2/5">
                <h2 className="font-beausite text-2xl mt-10">Discussion</h2>
                <P className="mt-3">
                  We made the UI more spacious and added clear hierarchy to
                  differentiate between headings and comments. Made minor
                  quality of life improvements to make browsing easier.
                </P>
                <div className="bg-[#EAEDF5] flex justify-center gap-8 md:rounded-3xl p-12 mt-8 md:mx-0 -mx-8">
                  <img src={discussions} alt="" className="md:w-full w-2/3" />
                </div>
              </div>
            </div>
            {/* Resources */}
            <div className="flex flex-row justify-start md:mt-8 mt-8">
              <div>
                <h2 className="font-beausite text-2xl mt-10">Resources</h2>
                <P className="mt-3 md:w-2/5">
                  We added onboarding videos to guide new users. Interactions to
                  create, move, and delete resources were simplified. Distinct
                  icons were added for different data types to make it easier
                  for users to scan and locate resources.
                </P>
                <div className="bg-[#EAEDF5] flex justify-start gap-8 md:rounded-3xl p-12 mt-8 md:mx-0 -mx-8 overflow-scroll">
                  <img src={resource1} alt="" className="md:w-[30%] w-2/3" />
                  <img src={resource2} alt="" className="md:w-[30%] w-2/3" />
                  <img src={resource3} alt="" className="md:w-[30%] w-2/3" />
                </div>
              </div>
            </div>
            {/* Assessment */}
            <div className="flex justify-start gap-32 md:mt-8 mt-8">
              <div className="">
                <h2 className="font-beausite text-2xl mt-10">Assessment</h2>
                <P className="mt-3 md:w-2/5">
                  We decluttered the page and streamlining the design. Many
                  small improvements were made in the assessment evaluation
                  flow, reducing cognitive load for teachers.
                </P>
                <div className="bg-[#EAEDF5] flex justify-start gap-8 md:rounded-3xl p-12 mt-8 md:mx-0 -mx-8 overflow-scroll">
                  <img src={assessment1} alt="" className="md:w-[30%] w-2/3" />
                  <img src={assessment2} alt="" className="md:w-[30%] w-2/3" />
                  <img src={assessment3} alt="" className="md:w-[30%] w-2/3" />
                </div>
              </div>
            </div>
            {/* Other screens */}
            <div className="flex flex-row justify-start gap-32 md:mt-8 mt-8">
              <div className="">
                <h2 className="font-beausite text-2xl mt-10">Other screens</h2>

                <div className="bg-[#EAEDF5] flex md:flex-row md:flex-wrap flex-col items-center md:gap-8 gap-16 md:rounded-3xl p-12 mt-8 md:mx-0 -mx-8">
                  <img src={wise1} alt="" className="md:w-[30%] w-[80%] " />
                  <img src={wise2} alt="" className="md:w-[30%] w-[80%] " />
                  <img src={wise3} alt="" className="md:w-[30%] w-[80%] " />
                  <img src={wise4} alt="" className="md:w-[30%] w-[80%] " />
                  <img src={wise5} alt="" className="md:w-[30%] w-[80%] " />
                  <img src={wise6} alt="" className="md:w-[30%] w-[80%] " />
                  <img src={wise7} alt="" className="md:w-[30%] w-[80%] " />
                  <img src={wise8} alt="" className="md:w-[30%] w-[80%] " />
                </div>
                <P className="mt-12 md:w-2/5">
                  Also, we moved away from React Native and rebuilt the app
                  using native Swift, making it smoother and more responsive.
                  This change addressed user feedback about the app's
                  performance and played a crucial part in making the app feel
                  delightful to use.
                  <br /> <br />
                  The goal behind these changes was simple - make the app more
                  intuitive, delightful, and efficient for our users.
                </P>
              </div>
            </div>
          </div>
        </div>
        {/* Learnings and Output */}
        <div className="flex justify-center">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              RESULTS
            </p>
            <H>
              Learnings & <span className="font-laica">output</span>
            </H>
            <div className="md:w-2/5 mt-6 flex flex-col gap-3">
              <P>
                The results of our revamp project were overwhelmingly positive.
              </P>
              <ul className="list-disc pl-4">
                <li className="mt-2">
                  <P>
                    We saw a significant improvement in our App Store rating,
                    rocketing from a 3.5 to a stellar 4.5.
                  </P>
                </li>
                <li className="mt-2">
                  <P>
                    Thanks to our shift to native Swift, we were able to squash
                    90% of the bugs related to Zoom.
                  </P>
                </li>
                <li className="mt-2">
                  <P>
                    Although we didn't see an immediate growth in our user base
                    or session time, the substantial improvements in other areas
                    provided a solid foundation for future growth.
                  </P>
                </li>
              </ul>
              <P>
                Our users were not shy in sharing their delight over the
                changes. We received a multitude of testimonials, praising
                everything from the design to the utility of our app:
              </P>
              <ul className="list-disc pl-4">
                <li>
                  <P>
                    "Best app for online teaching! I've tried a number of online
                    teaching tools and this is by far the best one... Even my
                    students and their parents are very happy that I chose this
                    app."
                  </P>
                </li>
                <li>
                  <P>
                    "Wonderfully designed and much needed App! Hassle-free way
                    for teachers to conduct classes, and students to join
                    them..."{" "}
                  </P>
                </li>
                <li>
                  <P>
                    "I can say with certainty that it is the best platform for
                    an enriching online educational experience..."
                  </P>
                </li>
              </ul>
              <P>
                {" "}
                The feedback served as validation for our hard work and a
                testament to our principles of delight and simplicity.
              </P>
              <P>
                But every project, no matter how successful, has invaluable
                lessons to teach. From this project, we learned the profound
                impact that a design system can have on consistency across
                numerous screens, and how this consistency can significantly
                boost user satisfaction.
                <br />
                <br />
                The shift to native Swift, while demanding, reiterated that the
                effort invested in adopting native technologies can drastically
                improve the app's performance, specifically seen in the reduced
                Zoom-related bugs.
              </P>
            </div>
          </div>
        </div>

        {/* Next case study */}
        <Nextcase to="/synaptic">
          Synaptic <span className="font-laica">Mobile</span>
        </Nextcase>
      </div>
    </div>
  )
}

export default Wise
