import profile from "../static/profile.pdf"
import tt_thumbnail_page from "../static/tt_thumbnail_page.webp"
import wise_hero from "../static/wise_hero.webp"
import Synaptic_hero from "../static/Synaptic_case_thumbnail.webp"
import insight_hero from "../static/insight_hero.webp"
import myphoto from "../static/myphoto.webp"
import urban_hero from "../static/urban_hero.webp"
import { useEffect, useState } from "react"

const Footer = () => {
  let imgList = [
    tt_thumbnail_page,
    wise_hero,
    Synaptic_hero,
    insight_hero,
    urban_hero,
    myphoto,
  ]

  let [preLoadImg, setPreLoadImg] = useState([])

  useEffect(() => {
    setTimeout(() => {
      setPreLoadImg(imgList)
    }, 1000)
  }, [])

  return (
    <div className="flex flex-col md:flex md:flex-row gap-4 md:gap-0 my-32  md:mb-16 justify-center md:justify-between items-center text-black font-neue text-base md:text-lg opacity-80 tracking-wide">
      <p>hi@sumitkumarr.com</p>
      <div className="flex gap-8">
        <a
          href="https://www.linkedin.com/in/sumit-kumar-asdf/"
          target="_blank"
          className="hover:underline underline-offset-2"
        >
          {" "}
          Linkedin
        </a>
        <a
          href={profile}
          target="_blank"
          className="hover:underline underline-offset-2"
        >
          {" "}
          Resume
        </a>
      </div>
      <div style={{ position: "absolute", opacity: 0 }}>
        {preLoadImg.map((item) => (
          <img key={item} src={item} style={{ width: "20px" }} />
        ))}
      </div>
    </div>
  )
}

export default Footer
