// components
import CaseHero from "../component/CaseHero"
import H from "../component/Title"
import P from "../component/Paragraph"
import Nextcase from "../component/Nextcase"

// static
import urban_hero from "../static/urban_hero.webp"
import urban_overview from "../static/urban_overview.webp"
import urban_align from "../static/urban_align.webp"
import urban_journey from "../static/urban_journey.webp"
import urban_sol1 from "../static/urban_sol1.webp"
import urban_sol2 from "../static/urban_sol2.webp"
import urban_vd1 from "../static/urban_vd1.webp"
import urban_vd2 from "../static/urban_vd2.webp"
import urban_vd3 from "../static/urban_vd3.webp"

// gsap
import { gsap, Power3, Power2 } from "gsap"
import { useRef, useEffect } from "react"

const Urban = () => {
  let containerRef = useRef(null)

  useEffect(() => {
    const containerEl = containerRef.current

    gsap.from(containerEl, {
      opacity: 0.8,
      duration: 0.7,
      ease: Power2.easeOut,
      y: 50,
    })
  }, [])

  return (
    <div className="" ref={containerRef}>
      <CaseHero
        thumb={urban_hero}
        case="DESIGN SPRINT FOR URBAN PIPER"
        des="I joined Parallel labs team in Bangalore as a Product designer in 2018. While collaborating with multidisciplinary teams, I helped shape Urban piper architecture.
        From conception to completion, I was part of the design sprint which includes researching, developing storyboards and user journey frameworks, wireframing, prototyping, and visual design, as well as pitching and presenting design solutions to stakeholders and clients."
        role={["Lead design", "Prototype", "User testing"]}
        team={["Robin Dhanwani - Sprint Facilitator"]}
        link="www.urbanpiper.com"
      >
        Urban
        <span className="font-laica font-normal"> Piper</span>
      </CaseHero>

      <div className="flex flex-col md:gap-32 gap-32 md:mt-40 mt-32">
        {/* Project overview */}
        <div className="flex justify-center">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              BACKGROUND
            </p>
            <H>
              Project <span className="font-laica">overview</span>
            </H>
            <div className="flex md:flex-row flex-col gap-6 justify-between items-start">
              <div className="md:w-2/5 w-full md:mt-6 flex flex-col gap-8 md:order-1 order-2">
                <P>
                  Urban Piper provides business automation solutions for
                  restaurants and cloud kitchens. They help them get their
                  businesses online and increase their in-store sales through
                  loyalty programs. The platform is used by brands such as
                  Eat.Fit, Chai Point and Cafe Coffee Day as a swiss knife for
                  their online ordering & catalogue management.
                </P>
              </div>

              <div className="md:w-[35%] w-[80%] md:mt-0 mt-4  mx-auto md:order-2 order-1 p-8 rounded-xl">
                <img src={urban_overview} alt="" className="" />
              </div>
            </div>
          </div>
        </div>

        {/* Key challenges */}
        <div className="flex justify-center">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              RESEARCH
            </p>
            <H>
              Key <span className="font-laica">challenges </span>
            </H>
            <div className="md:w-2/5 mt-6 flex flex-col">
              <P className="mt-0">
                We started the sprint by interviewing key stakeholders on the
                team about product goals and the challenges they were facing.
                Urban Piper had a product for restaurants and cloud kitchens to
                manage their catalogue across 8+ food aggregators (including but
                not limited to Uber eats, Zomato, Swiggy, Foodpanda),
                availability across multiple locations, run marketing programs
                and analyse data. Unfortunately, it was a power-packed product
                that needed daily assistance from Urban Piper's support team for
                them to be able to use. And that's precisely the puzzle we
                stepped in to solve.
              </P>
              <P className="font-medium mt-6">
                Some key challenges that came up were:
              </P>
              <ul className="list-disc pl-4">
                <li className="mt-2">
                  <P>
                    Users were not using the product effectively, and they were
                    shooting a lot of support requests due to the convoluted UI
                  </P>
                </li>
                <li className="mt-2">
                  <P>
                    Users found the product too confusing and often didn't even
                    knew about many useful features that existed.
                  </P>
                </li>
                <li className="mt-2">
                  <P>
                    Users found it hard to update and manager investory across
                    different channels
                  </P>
                </li>
                <li className="mt-2">
                  <P>
                    Users were not creating any campaigns and were not familiar
                    with the loyalty programs offered by Urban piper.
                  </P>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Aligning on the problems */}
        <div className="flex justify-center">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              ALIGNMENT
            </p>
            <H>
              Aligning on <span className="font-laica">problems</span>
            </H>
            <div className="flex md:flex-row flex-col gap-6 justify-between items-start">
              <div className="md:w-2/5 w-full md:mt-6 flex flex-col gap-2 md:order-1 order-2">
                <P>
                  We aligned on opportunities to solve during the sprint and
                  prioritized them:
                </P>
                <ul className="list-disc pl-4">
                  <li className="mt-2">
                    <P>How might we minimise support queries?</P>
                  </li>
                  <li className="mt-2">
                    <P>How might we make the product onboarding easy?</P>
                  </li>
                  <li className="mt-2">
                    <P>
                      How might we engage users to come back on the platform?
                    </P>
                  </li>
                  <li className="mt-2">
                    <P>
                      How might we make the anaytics more relevant for the end
                      users?
                    </P>
                  </li>
                </ul>
                <P className="mt-2">
                  After a discussion around the challenges, the team deliberated
                  on their 2 year product goal as:
                </P>
                <P className="mt-2 md:text-xl font-medium">
                  Have a global value proposition and be looking to build a
                  community of services
                </P>
              </div>

              <div className="md:w-[60%] w-full md:mt-0 mt-4  md:mx-auto md:order-2 order-1 md:p-8 p-4 rounded-xl">
                <img src={urban_align} alt="" className="" />
              </div>
            </div>
          </div>
        </div>

        {/* Persona and user journey */}
        <div className="flex justify-center">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              RESEARCH
            </p>
            <H>
              Persona and <span className="font-laica">user journey</span>
            </H>
            <div className="flex md:flex-row flex-col gap-6 justify-between items-start">
              <div className="md:w-2/5 w-full md:mt-6 flex flex-col gap-2 md:order-1 order-2">
                <P>
                  We then discussed the user personas and their tasks on the
                  platform to map the challenge. It was a product used by a wide
                  variety of users for different purposes. We identified 5
                  different personas and their core reasons to use the product.
                </P>
                <ul className="list-disc pl-4">
                  <li className="mt-2">
                    <P>
                      <span className="font-medium">Business manager - </span>
                      Analyzes data to provide valuable insights
                    </P>
                  </li>
                  <li className="mt-2">
                    <P>
                      <span className="font-medium">Operations manager - </span>
                      Monitors the catalogue and sets up the system initially
                    </P>
                  </li>
                  <li className="mt-2">
                    <P>
                      <span className="font-medium">Catalogue manager - </span>
                      Manages the catalogue across stores
                    </P>
                  </li>

                  <li className="mt-2">
                    <P>
                      <span className="font-medium">Accounts team - </span>
                      Tracks reports on financial performance across stores
                    </P>
                  </li>
                  <li className="mt-2">
                    <P>
                      <span className="font-medium">Marketing team - </span>
                      Marketing team - Manages & analyzes marketing activities
                    </P>
                  </li>
                </ul>
              </div>

              <div className="md:w-[60%] w-full md:mt-0 mt-4  md:mx-auto md:order-2 order-1 md:p-8 p-4 rounded-xl">
                <img src={urban_journey} alt="" className="" />
              </div>
            </div>
          </div>
        </div>

        {/* Key challenges and opportunities */}
        <div className="flex justify-center">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              PROBLEM
            </p>
            <H>
              Key challenges & <span className="font-laica">opportunities</span>
            </H>

            <div className="md:w-2/5 w-full md:mt-6 mt-4 flex flex-col gap-2 ">
              <P>
                After mapping the challenge, patterns emerged, which further
                clarified the direction for the sprint. We learn that:
              </P>
              <ul className="list-disc pl-4">
                <li className="mt-2">
                  <P>
                    <span className="font-medium">Onboarding - </span>
                    The first time exposure to the product was always through a
                    demo. The users needed a guided tour of the application
                    before it can be used.
                  </P>
                </li>
                <li className="mt-2">
                  <P>
                    <span className="font-medium">Navigation - </span>
                    There was a lot of focus around navigability of the
                    application, and it needed a fix.
                  </P>
                </li>
                <li className="mt-2">
                  <P>
                    <span className="font-medium">Data visualization</span>, if
                    done correctly, can help make the platform sticky by showing
                    informative & relevant data to the users clearly and
                    concisely.
                  </P>
                </li>

                <li className="mt-2">
                  <P>
                    <span className="font-medium">Next steps - </span>
                    With a deluge of data from all the channels (Online orders,
                    Aggregators, Offline stores), the platform needed to be
                    smart, provide deep insights and recommend the next steps so
                    as not to overwhelm the end-user.
                  </P>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Ideating solutions */}
        <div className="flex justify-center">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              SOLUTION
            </p>
            <H>
              Ideating <span className="font-laica">solutions</span>
            </H>

            <div className="md:w-2/5 w-full md:mt-6 mt-4 flex flex-col gap-2 ">
              <P>
                At this point, we knew the shortcomings of the platform -
                navigation with confusing hierarchy, improper metrics for
                analytics, the requirement of a demo to be used for the first
                time. The next step was to brainstorm through these problems and
                come up with solutions. Key among these issues was the
                Information architecture. Once we get that right rest of the
                components will fall in place automatically like Tetris blocks.
                With that in mind and hands-on paper, we set out to work on the
                structure fleshing out essential details.
                <br />
                <br />
                We had some great ideas which promised to solve some of the key
                problems identified by the team. We used these ideas to create a
                blueprint for the revised product designs.
              </P>
            </div>
            <div className="flex md:flex-row flex-col gap-8 mt-10">
              <div className="md:w-[44%]">
                <div className="md:w-full flex justify-center rounded-xl items-start">
                  <img src={urban_sol1} alt="" className="md:w-full w-4/5" />
                </div>
              </div>

              <div className="md:w-[44%]">
                <div className="md:w-full flex justify-center rounded-xl">
                  <img src={urban_sol2} alt="" className="md:w-full w-4/5" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Wireframes & visual designs */}
        <div className="flex justify-center">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              VISUAL DESIGN
            </p>
            <H>
              Wireframes & <span className="font-laica">visual designs</span>
            </H>

            <div className="md:w-2/5 w-full md:mt-6 mt-4 flex flex-col gap-2 ">
              <P>
                We envisoned the dashboard as the central location to push
                important features to users. Since we had different personas
                engaging at different phases, we wanted to ensure that the key
                workflows gets precedence over fringe use cases.
              </P>
              <P className="font-bold mt-6">
                RECOMMENDED ACTIONS FOR OPERATION MANAGERS
              </P>
              <P>
                An operations manager sets up the system initially by uploading
                a catalogue of items and configuring the product to meet his/her
                business requirements. A majority of the support calls were
                regarding setting up the inventory. After signing up, users felt
                lost, they didn't know what the next steps were to get the
                system up and running. To fix this, I added an onboarding wizard
                on dashboard. The instructions provide next steps to setup a
                module.
              </P>
              <P className="font-bold mt-6">A BIRD'S EYE VIEW OF BUSINESS</P>
              <P>
                After the initial setup, the workflows branched away. Catalogue
                managers handled day to day inventory, accounts needed a weekly
                report & marketing managed campaigns. On the dashboard, we
                wanted to highlight important activities and give an impetus to
                the users to explore further. Based on the initial research, I
                add a time-series for Sales, Orders & New customers, grouped by
                type of channel. These metrics are tracked on a day to day
                basis. You can drill down further in analytics section.
                Similarly all the active campaigns were shown along with most
                sold items.
              </P>
            </div>
            <div className="p-12 bg-[#EAF0FF] md:rounded-xl mt-8 md:mx-0 -mx-8">
              <img src={urban_vd1} alt="" />
            </div>
            <div className="md:w-2/5 w-full md:mt-6 mt-4 flex flex-col gap-2 ">
              <P className="font-bold mt-6">PRODUCT LISTING & OPTIONS</P>
              <P>
                A central inventory management was the core offering of Urban
                piper. Once you have the inventory setup, you can push those
                products to an aggregator of your choice(such as Swiggy, Zomato
                or Uber eats), add dynamic coupons, analyze orders, run
                campaigns and much more. All of the above functionality depended
                on the configuration each product. I collated all the
                configurations into one view.
              </P>
            </div>
            <div className="p-12 bg-[#EAF0FF] md:rounded-xl mt-8 md:mx-0 -mx-8">
              <img src={urban_vd2} alt="" />
              <img src={urban_vd3} alt="" className="mt-8" />
            </div>
          </div>
        </div>

        {/* User Testing */}
        <div className="flex justify-center">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              TESTING
            </p>
            <H>
              User <span className="font-laica">testing</span>
            </H>

            <div className="md:w-2/5 w-full md:mt-6 mt-4 flex flex-col gap-2 ">
              <P>
                I tested prototypes with actual customers of Urban Piper who
                were using the current product to unlock insights on their
                response to both options and make informed decisions.
              </P>
              <ul className="list-disc pl-4">
                <li className="mt-2">
                  <P>
                    They liked the inclusion of key metrics on Dashboard. They
                    requested an app to further notify them on important events.
                  </P>
                </li>
                <li className="mt-2">
                  <P>They found the inventory management workflow simpler.</P>
                </li>
                <li className="mt-2">
                  <P>
                    They loved the new architecture and found it easy to
                    navigate between sections.
                  </P>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Sprint results */}
        <div className="flex justify-center">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              OUTPUT
            </p>
            <H>
              Sprint <span className="font-laica">results</span>
            </H>

            <div className="md:w-2/5 w-full md:mt-6 mt-4 flex flex-col gap-2 ">
              <ul className="list-disc pl-4">
                <li className="mt-2">
                  <P>We were able to bring down the support queries by 24%.</P>
                </li>
                <li className="mt-2">
                  <P>
                    There was a significant reduction in the time it took to
                    setup the inventory.
                  </P>
                </li>
                <li className="mt-2">
                  <P>
                    The redesign led to an increase in adoption of Analytics &
                    Campaign manager.
                  </P>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Next case study */}
        <Nextcase to="/tickertape">
          Tickertape <span className="font-laica">Homepage</span>
        </Nextcase>
      </div>
    </div>
  )
}

export default Urban
