const P = (props) => {
  let spacing = props.spacing ? props.spacing : 2
  let padding = "pt-" + spacing
  let className =
    padding +
    "  font-neue tracking-wide md:text-black/80 text-black/90 md:text-base tracking-wide" +
    " " +
    props.className

  return <p className={className}>{props.children}</p>
}

export default P
