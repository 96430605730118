import { BrowserRouter, Route, Routes } from "react-router-dom"
import Home from "./pages/Home"
import About from "./pages/About"
import Contact from "./pages/Contact"
import Layout from "./pages/Layout"
import Wise from "./pages/Wise"
import Synaptic from "./pages/Synaptic"
import Insight from "./pages/Insight"
import Urban from "./pages/Urban"
import Tickertape from "./pages/Tickertape"

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/wise" element={<Wise />} />
          <Route path="/synaptic" element={<Synaptic />} />
          <Route path="/insights" element={<Insight />} />
          <Route path="/urban" element={<Urban />} />
          <Route path="/tickertape" element={<Tickertape />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
