import tt_thumbnail from "../static/tt_thumbnail.webp"
import wise_thumbnail from "../static/wise_thumbnail.webp"
import synaptic_thumbnail from "../static/synaptic_thumbnail.webp"
import urban_thumbnail from "../static/urban_thumbnail.webp"
import insights_thumbnail from "../static/insights_thumbnail.webp"
import Thumbnail from "../component/Thumbnail"
import { gsap, Power3, Power2 } from "gsap"
import { ScrollTrigger } from "gsap/all"
import { useRef, useEffect } from "react"

gsap.registerPlugin(ScrollTrigger)

const Home = () => {
  let heroRef = useRef(null)
  let workRef = useRef(null)
  let desRef = useRef(null)
  let circleRef = useRef(null)
  let tl = gsap.timeline()

  const hours = new Date().getHours()
  const afternoon =
    "w-[60%] md:w-[42%] aspect-square absolute left-[20%] rounded-full bg-gradient-to-b from-yellowd via-yellowl via-20% to-50%"
  const morning =
    "w-[60%] md:w-[42%] aspect-square absolute left-[20%] rounded-full bg-gradient-to-b from-[#38FFDB] via-[#FFE178]/[0.3] via-40% to-50%"
  const evening =
    "w-[60%] md:w-[42%] aspect-square absolute left-[20%] rounded-full bg-gradient-to-b from-[#4560ED] via-[#78FFFF]/[0.3] via-40% to-50%"

  let className = ""
  let time = ""

  if (hours > 5 && hours <= 11) {
    className = morning
    time = "morning"
  } else if (hours > 11 && hours < 17) {
    time = "afternoon"
    className = afternoon
  } else if (hours >= 17 && hours < 21) {
    time = "evening"
    className = evening
  } else if ((hours >= 21 && hours <= 24) || (hours >= 0 && hours <= 5)) {
    time = "evening"
    className = evening
  }

  useEffect(() => {
    const heroEl = heroRef.current
    const workEl = workRef.current
    const circleEl = circleRef.current
    const desEl = desRef.current
    tl.from(heroEl, {
      opacity: 0,
      y: 20,
      duration: 0.8,
      ease: Power2.easeOut,
    })

    gsap.from(circleEl, {
      opacity: 0,
      y: 30,
      x: -50,
      scale: 0.7,
      duration: 1.8,
      ease: Power2.easeOut,
    })

    gsap.from(workEl, {
      opacity: 0,
      duration: 1,
      ease: Power3.easeOut,
      scrollTrigger: {
        trigger: workEl,
        start: "top 80%",
        end: "top 40%",
        triggerActions: "play none none none none",
        scrub: true,
      },
    })
  }, [])

  return (
    // ---------------- Home container ----------------
    <div className="mt-32 mb-24 text-black">
      {/*  ---------------- Spinner ----------------  */}
      <div className={className} ref={circleRef}></div>
      {/* // ---------------- Hero ----------------  */}
      <div className=" relative max-w-4xl hero">
        <div className="text-base md:text-lg mb-4 text-gray-500 tracking-wide">
          Good {time}
        </div>
        {/* // ---------------- Title ----------------  */}
        <div
          className="text-5xl md:text-7xl leading-snug md:leading-tight font-beausite"
          ref={heroRef}
        >
          I'm Sumit, <span className="font-laica">Product designer</span> based
          out of Bangalore
        </div>
        <div className="flex justify-end">
          {/* // ---------------- Description ----------------  */}
          <div
            className="mt-12 w-72 md:w-96 font-neue text-base md:text-lg leading-relaxed tracking-wide des"
            ref={desRef}
          >
            Hey there! 👋🏼 I've been designing stuff for more than 7 years now.
            Design, to me, is a meaningful act of bringing intentionality to the
            process of problem solving. It's pure joy.
          </div>
        </div>
      </div>

      {/* // ---------------- Selected works ----------------  */}
      <div className="work" ref={workRef}>
        <div className="font-beausite font-medium text-4xl md:text-5xl w-max mx-auto mb-12 md:mb-24 mt-32 md:mt-48 ">
          Selected <span className="font-laica font-medium">works</span>
        </div>
        {/* // ---------------- Thumbnails ----------------  */}
        <div className="flex">
          <div className="flex flex-row flex-wrap justify-center gap-16">
            <Thumbnail
              thumbnail={tt_thumbnail}
              title="Tickertape homepage"
              subtitle="Storytelling, Visual design, Landing page"
              bg="blue"
              justify="yes"
              to="tickertape"
            ></Thumbnail>
            <Thumbnail
              thumbnail={wise_thumbnail}
              title="Wise app"
              subtitle="iOS, Design system, Visual design"
              bg="grey"
              to="wise"
            ></Thumbnail>
            <Thumbnail
              thumbnail={synaptic_thumbnail}
              title="Synaptic mobile"
              subtitle="Alternative data, Research, Prototype, Design"
              bg="pink"
              to="synaptic"
            ></Thumbnail>
            <Thumbnail
              thumbnail={insights_thumbnail}
              title="Synaptic insights"
              subtitle="Alternative data, Research, Prototype, Design"
              bg="yellow"
              justify="yes"
              to="insights"
            ></Thumbnail>
            <Thumbnail
              thumbnail={urban_thumbnail}
              title="Urban Piper"
              subtitle="Design Sprint, Foodtech"
              bg="green"
              justify="yes"
              to="urban"
            ></Thumbnail>
            <div className="w-[36%] aspect-[1.13] md:block hidden"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
