import { Link } from "react-router-dom"
import Logo from "../static/Logo.svg"
import Logo_small from "../static/Logo_small.svg"
import { useLocation } from "react-router-dom"

import { useRef, useEffect } from "react"

const Header = () => {
  const { pathname } = useLocation()
  let logoRef = useRef(null)
  let smallLogoRef = useRef(null)

  let menu = {
    Work: "/",
    About: "/about",
    Contact: "/contact",
  }

  useEffect(() => {
    const logoEl = logoRef.current
    const smallLogoEl = smallLogoRef.current

    window.addEventListener(
      "scroll",
      function () {
        if (window.scrollY > 200) {
          smallLogoEl.style.display = "block"
          logoEl.style.display = "none"
        } else {
          smallLogoEl.style.display = "none"
          logoEl.style.display = "block"
        }
      },
      false
    )
  }, [])

  const active = "px-5 py-2 rounded-lg bg-white w-[30%] flex justify-center"
  const inactive =
    "px-5 py-2 rounded-lg hover:bg-white w-[30%] flex justify-center"

  return (
    <div className="flex my-10 md:my-8 justify-center md:justify-between items-center md:sticky md:top-8 z-10">
      <Link to="/">
        <div className="">
          <img
            src={Logo}
            className="big_logo transition-all duration-150 opacity-100"
            ref={logoRef}
          />
          <img
            src={Logo_small}
            className="small_logo transition-all duration-100 hidden"
            ref={smallLogoRef}
          />
        </div>
      </Link>
      <div className="z-50 backdrop-blur-lg fixed md:static bottom-5 left-8 right-8 font-neue text-black bg-light-grey/60 text-base font-medium md:font-normal tracking-normal md:tracking-wide flex  justify-center items-center gap-2 p-2.5 rounded-lg">
        {Object.keys(menu).map((item) => {
          return (
            <div
              key={item}
              className={pathname === menu[item] ? active : inactive}
            >
              <Link to={menu[item]}>{item}</Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Header
