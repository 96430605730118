// gsap
import { gsap, Power3, Power2 } from "gsap"
import { useRef, useEffect } from "react"

const Contact = () => {
  let containerRef = useRef(null)

  useEffect(() => {
    const containerEl = containerRef.current

    gsap.from(containerEl, {
      opacity: 0.8,
      duration: 2,
      ease: Power2.easeOut,
      y: 100,
    })
  }, [])

  return (
    // container
    <>
      {/* email */}
      <div className="flex justify-center md:my-[25vh] my-[26vh] z-10 relative bg-white">
        <h1 className="font-laica font-medium md:text-[72px] text-[38px] md:tracking-tighter leading-[3em]">
          hi@sumitkumarr.com
        </h1>
      </div>

      {/* Looking for new opportunities */}
      <div
        className="absolute md:top-0 top-4 left-0 right-0 text-center flex flex-col gap-6 md:max-h-[100vh] max-h-[70vh] overflow-hidden z-[-1]"
        ref={containerRef}
      >
        <p className="font-beausite md:text-xl">
          🌟 Looking for new opportunities 🌟
        </p>
        <p className="font-laica md:text-xl">
          🧑‍💻 Looking for new opportunities 🧑‍💻
        </p>
        <p className="font-beausite md:text-xl">
          🍻 Looking for new opportunities 🍻
        </p>
        <p className="font-laica md:text-xl">
          🍎 Looking for new opportunities 🍎
        </p>
        <p className="font-beausite md:text-xl">
          🔥 Looking for new opportunities 🔥
        </p>
        <p className="font-laica md:text-xl">
          🎨 Looking for new opportunities 🎨
        </p>
        <p className="font-beausite md:text-xl">
          🏓 Looking for new opportunities 🏓
        </p>
        <p className="font-beausite md:text-xl">
          🌟 Looking for new opportunities 🌟
        </p>
        <p className="font-laica md:text-xl">
          🧑‍💻 Looking for new opportunities 🧑‍💻
        </p>
        <p className="font-beausite md:text-xl">
          🍻 Looking for new opportunities 🍻
        </p>
        <p className="font-laica md:text-xl">
          🍎 Looking for new opportunities 🍎
        </p>
        <p className="font-beausite md:text-xl">
          🔥 Looking for new opportunities 🔥
        </p>
        <p className="font-laica md:text-xl">
          🎨 Looking for new opportunities 🎨
        </p>
        <p className="font-beausite md:text-xl">
          🏓 Looking for new opportunities 🏓
        </p>
        <p className="font-beausite md:text-xl">
          🌟 Looking for new opportunities 🌟
        </p>

        <div className="md:hidden h-[20vh]"></div>
      </div>
    </>
  )
}

export default Contact
