// components
import CaseHero from "../component/CaseHero"
import H from "../component/Title"
import P from "../component/Paragraph"
import Nextcase from "../component/Nextcase"

// static
import Synaptic_hero from "../static/Synaptic_case_thumbnail.webp"
import Funnel from "../static/Funnel.webp"
import Synaptic_home_old from "../static/Synaptic_home_old.webp"
import Synaptic_navgiation_new from "../static/Synaptic_navgiation_new.webp"
import Synaptic_analysis_old from "../static/Synaptic_analysis_old.webp"
import Synaptic_analysis_new from "../static/Synaptic_analysis_new.webp"
import Synaptic_gif from "../static/Synaptic_gif.gif"

// gsap
import { gsap, Power2 } from "gsap"
import { useRef, useEffect } from "react"

const Synaptic = () => {
  const resizeImage = () => {
    let interval = setInterval(() => {
      let containerHeight =
        document.querySelector(".small_container")?.clientHeight
      if (!containerHeight) return
      let elem = document.querySelector(".tall_container")
      if (containerHeight < 100) return
      clearInterval(interval)
      elem.style.height = containerHeight + "px"
    }, 100)
  }

  let containerRef = useRef(null)

  useEffect(() => {
    resizeImage()
    window.addEventListener("resize", resizeImage)

    const containerEl = containerRef.current

    gsap.from(containerEl, {
      opacity: 0.8,
      duration: 0.7,
      ease: Power2.easeOut,
      y: 50,
    })
  }, [])

  return (
    <div className="" ref={containerRef}>
      <CaseHero
        thumb={Synaptic_hero}
        case="MAKE SCREENING EASIER"
        des="Screening & evaluating companies is a core user activity on Synaptic, amounting to 90% of usage on dekstop – however, it was difficult to extract meaningful insights from the data presented in mobile app. I led the redesign to simplify compage profile so it was intuitive to browse."
        role={[
          "End to end design",
          "Requirements",
          "User research",
          "Prototyping",
        ]}
        team={["Dilpreet Singh - Engineer", "Shashank Jain - Engineer"]}
        company="Synaptic is an alternative data platform that helps financial firms and investors get actionable insights."
        layout="row"
      >
        Synaptic
        <span className="font-laica font-normal md:block md:mt-3"> Mobile</span>
      </CaseHero>

      {/* Container */}
      <div className="flex flex-col md:gap-32 gap-32 md:mt-40 mt-32">
        {/* Venture capital investment funnel */}
        <div className="flex justify-center">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              BACKGROUND
            </p>
            <H>
              Venture capital <span className="font-laica">investment</span>{" "}
              funnel
            </H>
            <div className="flex md:flex-row flex-col gap-6 justify-between">
              <div className="md:w-2/5 w-full mt-6 flex flex-col gap-8 md:order-1 order-2">
                <P>
                  A venture capitalist(VC) plays many roles—investor, business
                  owner, board member, recruiter, consultant, mentor, and
                  fundraiser, to name just a few. In contrast to a common
                  perception, an experienced VC will typically spend less than
                  half his/her time in activities related to new deal flow and
                  potential new investments. To sort through investment
                  opportunities, VCs use a multi-stage selection process, known
                  as the deal funnel.
                </P>
                <p className="font-neue text-xl">
                  At each subsequent stage a substantial number of opportunities
                  are eliminated. On an average a firm invests in less than 1%
                  of ~2000 companies they review in a year.
                </p>
                <P>
                  Due to the large number of potential investments, and the
                  small number that will eventually be funded, the initial
                  screening is not done in depth, but rather is intended to
                  identify those companies which warrant more detailed
                  examination. The objective is to assess the risks associated
                  with the proposal, turn down the “misfits” quickly, and focus
                  on the most promising investment opportunities.
                </P>
                <div>
                  <h3 className="font-neue font-bold tracking-wide mb-4">
                    SYNAPTIC POSITIONING
                  </h3>
                  <P>
                    Each VC has their own set of criteria on which they evaluate
                    potential opportunities. Early stage VC's may focus more on
                    the founding team while late stage VC may give more
                    weightage to user penetration of a product in a given
                    market. The core hypothesis of a firm pinballs between
                    product, market & team.
                    <br />
                    By aggregating data from multiple sources, Synaptic provides
                    a unified interface that enables VC's to get a complete
                    picture of a company.
                  </P>
                </div>
              </div>
              <div className="md:w-[34%] w-3/5 mt-12 mx-auto md:order-2 order-1">
                <img src={Funnel} alt="" className="" />
              </div>
              <div className="order-3"> </div>
            </div>
          </div>
        </div>
        {/* Why revamp mobile app */}
        <div className="flex justify-center">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              RESEARCH
            </p>
            <H>
              Why revamp <span className="font-laica">mobile</span> app
            </H>
            <div className="md:w-2/5 mt-6 flex flex-col gap-3">
              <P>
                An extensive study of usage data showed me that the mobile app
                was lagging behind desktop in almost every aspect. Users were
                abandoning workflows midway. Through user interviews & surveys,
                it became clear that there were massive pain points in mobile.
              </P>
              <ol className="font-neue tracking-wide md:text-black/80 text-black/90 md:text-base tracking-wide list-decimal ml-4">
                <li className="">Company profile took ~5s to load</li>
                <li className="">Missing data for many companies</li>
                <li className="">
                  Poor architecture made it hard to make sense of data
                </li>
                <li className="">
                  No notifications when a data source is updated
                </li>
                <li className="">No ability to compare competitors</li>
              </ol>
              <h3 className="font-neue font-bold tracking-wide mt-4">
                OPPORTUNITY
              </h3>
              <P>
                Not only was this project important because it was one of the
                top requests we continued to hear from customers, but it also
                provided tremendous value to the business. We saw mobile app as
                a way to increase user engagement, keep users in Synaptic, and
                to satisfy market demand.
                <br /> <br />
                Rather than opening multiple tabs on Similarweb, Appannie,
                Linkedin & Second measure, users trusted Synaptic to provide all
                the data for a company. The opportunity for Synaptic was
                specific: we could be an all in one tool to screen companies.
                And we saw mobile as leading the way, relegating due diligence
                to desktop. We prioritized a few key features that would be most
                valuable and feasible.
              </P>
              <div className="flex flex-col gap-4">
                <P>
                  <span className="font-medium">Show all entities:</span> We
                  only showed data for primary entities on mobile which led to a
                  perception that the app was incomplete. For ex: We only showed
                  metrics for Uber ride app even though Uber has multiple apps
                  on App store(Uber driver & Uber lite)
                </P>
                <P>
                  <span className="font-medium">Exhaustive data sources:</span>{" "}
                  Several key metrics such as User retention, App ranking,
                  Glassdoor ratings & Product reviews were missing from mobile.
                </P>
                <P>
                  <span className="font-medium">Compare competitors:</span> If
                  you're evaluating Uber, there's a good chance that you would
                  want to compare it with Lyft in US and Ola in India. This was
                  not possible on mobile.
                </P>
                <P>
                  <span className="font-medium">Data analysis:</span> Each chart
                  opened on a new page which made it difficult to compare
                  trends. You cannot plot month-over-month graph on mobile.
                </P>
              </div>
            </div>
          </div>
        </div>

        {/* Better architecture */}
        <div className="flex justify-center">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              SOLUTION
            </p>
            <H>
              Better <span className="font-laica">architecture</span>
            </H>
            <div className="md:w-2/5 mt-6 flex flex-col gap-3">
              <P>
                Previsouly, all of the metrics for a company were laid out in a
                flat heirarchy – a single scrollable view. This made it
                difficult to navigate to a specific data source. I added a
                Floating button so that users can easily switch to an entity
                they're interested in evaluating.
              </P>
            </div>
            <div className="flex md:flex-row flex-col gap-8 mt-10">
              <div className="md:w-[40%]">
                <div className="md:w-full flex md:overflow-scroll overflow-hidden  justify-center p-8 bg-[#EAF0FF] rounded-xl items-start tall_container">
                  <img src={Synaptic_home_old} alt="" className="w-full" />
                </div>
                <P className="mt-6">
                  <span className="font-bold">Before:</span> All entities were
                  stacked vertically. This made it hard to build a mental map of
                  data for a company
                </P>
              </div>

              <div className="md:w-[40%]">
                <div className="md:w-full flex justify-center p-8 bg-[#EAF0FF] rounded-xl small_container">
                  <img
                    src={Synaptic_navgiation_new}
                    alt=""
                    className="w-full"
                  />
                </div>
                <P className="mt-6">
                  <span className="font-bold">After: </span>
                  The navigation is abstracted away into the floating action
                  button making it easy to find an entity
                </P>
              </div>
            </div>
            <div className="md:w-2/5 md:mt-16 mt-12 flex flex-col gap-3">
              <P>
                Another reason for introducing the FAB was to decouple Company
                overview and Data source detail. While sceening a company, a VC
                first takes a cursory glance of key metrics such as Funding
                history, Investors, Industry, Founders background etc. before
                diving deep into a data source. Decoupling the overview & detail
                section enabled us to cater to that workflow.
              </P>
            </div>
          </div>
        </div>

        {/* Simplifying analysis */}
        <div className="flex justify-center">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              SOLUTION
            </p>
            <H>
              Simplifying <span className="font-laica">analysis</span>
            </H>
            <div className="md:w-2/5 mt-6 flex flex-col gap-3">
              <P>
                All metrics for a company were correlated. An increase in Sales
                can be attributed to expanding Customer base or it can be due to
                a growth in Spending per customer. With the redesign, our
                primary goal was to highlight these web of connections and
                quickly establish the big picture for a data source.
              </P>
              <div className="mt-6">
                <h3 className="font-neue font-bold tracking-wide mb-2">
                  YoY growth
                </h3>
                <P>
                  I added pills for MoM & YoY alongside the absolute values. YoY
                  chart helps you establish the rate of growth for a metric. A
                  flat line for YoY would indicate a constant growth Year over
                  Year while an upward or download slope signals
                  accelerating/decelearing growth. This is useful for VC's
                  evaluating Early stage & late stage companies.
                </P>
              </div>
            </div>
            {/* Images */}
            <div className="flex md:flex-row flex-col gap-8 mt-10">
              <div className="md:w-[40%]">
                <div className="md:w-full flex overflow-scroll justify-center p-8 bg-[#EAF0FF] rounded-xl items-start">
                  <img src={Synaptic_analysis_old} alt="" className="w-full" />
                </div>
                <P className="mt-6">
                  <span className="font-bold">Before:</span> It's difficult to
                  compare metrics in tiles view
                </P>
              </div>

              <div className="md:w-[40%]">
                <div className="md:w-full flex justify-center p-8 bg-[#EAF0FF] rounded-xl">
                  <img src={Synaptic_analysis_new} alt="" className="w-full" />
                </div>
                <P className="mt-6">
                  <span className="font-bold">After: </span>
                  In tabular view, you can easily see the connections. The
                  accent colors further provides a macro view of performance
                </P>
              </div>
            </div>

            <div className="md:w-2/5 mt-12 flex flex-col gap-3">
              <P>
                The chart opens up in the same view. This allows you to evaluate
                a metric without losing context. You can also open multiple
                charts at once and compare trends across different metrics.
              </P>

              <div className="md:w-full flex overflow-scroll justify-center p-8 bg-[#EAF0FF] rounded-xl items-start mt-6">
                <img src={Synaptic_analysis_old} alt="" className="w-full" />
              </div>
            </div>
          </div>
        </div>

        {/* Faster Loading */}
        <div className="flex justify-center">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              SOLUTION
            </p>
            <H>
              Faster <span className="font-laica">loading</span>
            </H>
            <div className="flex md:flex-row flex-col gap-6 items-start justify-between">
              <div className="md:w-2/5 mt-6 flex flex-col gap-8 md:order-1 order-2">
                <P>
                  Changing the architecture also allowed us to bring down the
                  loading time of company profile from ~7s to less then ~1.5s.
                  While previously, all the data had to be loaded before
                  anything rendered on the screen, now we divides up the calls
                  into sections and rendered them sequentially.
                  <br />
                  <br />I added progressive loading to the company profile.
                  Company meta(description, sector, funding) acts an anchor that
                  holds users attention and while they are reading the content,
                  the rest of the pge loads, giving an impressions of faster
                  loading time.
                </P>
              </div>
              <div className="md:w-[38%] p-8 bg-[#c4c7cd] rounded-2xl mt-6 md:order-2 order-1">
                <img src={Synaptic_gif} alt="" className="" />
              </div>
              <div className="order-3"> </div>
            </div>
          </div>
        </div>

        {/* Learnings and Output */}
        <div className="flex justify-center">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              RESULTS
            </p>
            <H>
              Learnings from the <span className="font-laica">project</span>
            </H>
            <div className="md:w-2/5 mt-6 flex flex-col gap-3">
              <P>
                This was a really exciting and challenging project for me to
                work on as it provides real value, involved a ton of research,
                and detailed interaction work. I learned some important
                takeaways from this project related to product and business
                processes.
              </P>
              <div className="flex flex-col gap-4 mt-4">
                <P>
                  <span className="font-medium">
                    Choosing what not to do is more important:
                  </span>{" "}
                  There were many great use cases that we could have tackled
                  with rich features. However, there was a cost associated with
                  each and I had to determine which ones provided value for
                  Synaptic.
                </P>

                <P>
                  <span className="font-medium">Defining an MVP:</span> I
                  learned how to define a true MVP, something that is shippable
                  and let's you test your hypothesis with minimum feature set
                </P>
                <P>
                  <span className="font-medium">Fight for good UX:</span> I had
                  to work under very strict technical constraints, but still
                  fight for what I believe is essential to having a good user
                  experience.
                </P>
              </div>
            </div>
          </div>
        </div>

        {/* Next case study */}
        <Nextcase to="/insights">
          Synaptic <span className="font-laica">Insights</span>
        </Nextcase>
      </div>
    </div>
  )
}

export default Synaptic
