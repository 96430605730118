import { Link, Outlet } from "react-router-dom"
import Footer from "../component/Footer"
import Header from "../component/Header"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"

const Layout = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <div className="flex justify-center overflow-clip	">
      <div className="max-w-6xl w-full mx-8 md:mx-28">
        <Header></Header>
        <Outlet />
        <Footer></Footer>
      </div>
    </div>
  )
}

export default Layout
