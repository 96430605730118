import { Link } from "react-router-dom"

const Thumbnail = (props) => {
  let bg = ""
  switch (props.bg) {
    case "blue":
      bg = "bg-bluebg"
      break
    case "grey":
      bg = "bg-greybg"
      break
    case "pink":
      bg = "bg-pinkbg"
      break
    case "yellow":
      bg = "bg-yellowbg"
      break
    case "green":
      bg = "bg-greenbg"
      break
    default:
      bg = "bg-greybg"
  }

  let justify = ""
  props.justify == "yes"
    ? (justify = "justify-end items-center")
    : (justify = "justify-center items-end")

  return (
    <div className="flex-col rounded-2xl w-[95%] md:w-[36%] transition-all duration-200 ">
      <div
        className={
          bg + " rounded-xl flex hover:-translate-y-2 transition-all " + justify
        }
      >
        <Link to={props.to}>
          <img src={props.thumbnail} alt="" className="max-h-[330px] " />
        </Link>
      </div>
      <div className="mt-6">
        <p className="font-beausite tracking-wide text-xl md:text-xl font-medium">
          {props.title}
        </p>
        <p className="mt-2 font-neue tracking-wide text-base md:text-lg text-black opacity-50">
          {props.subtitle}
        </p>
      </div>
    </div>
  )
}

export default Thumbnail
