// custom components

import H from "../component/Title"
import P from "../component/Paragraph"

// gsap
import { gsap, Power3, Power2 } from "gsap"
import { ScrollTrigger } from "gsap/all"
import { useRef, useEffect } from "react"

// static images
import myphoto from "../static/myphoto.webp"
import artofcover from "../static/artof_cover.webp"
import rationality from "../static/rationality_cover.webp"
import typecover from "../static/type_cover.webp"
import spectrum from "../static/spectrum_cover.webp"
import selfish from "../static/selfish_cover.webp"
import free from "../static/free_cover.webp"
import moral from "../static/moral_cover.webp"
import patience from "../static/Patience.webp"
import intensity from "../static/Intensity.webp"
import lying from "../static/Lying.webp"
import wonder from "../static/Wonder.webp"
import zen from "../static/zen.webp"
import running from "../static/running.webp"
import snooker from "../static/snooker.webp"
import swimming from "../static/swimming.webp"
import skating from "../static/skating.webp"

gsap.registerPlugin(ScrollTrigger)

const About = () => {
  let containerRef = useRef(null)

  useEffect(() => {
    const containerEl = containerRef.current

    gsap.from(containerEl, {
      opacity: 0.8,
      duration: 0.7,
      ease: Power2.easeOut,
      y: 50,
    })
  }, [])

  return (
    <div className="" ref={containerRef}>
      {/* Hero */}
      <div className="flex justify-center md:mt-24 mt-24">
        <div className="flex md:flex-row flex-col md:w-[72%] md:gap-20 gap-12 items-start">
          <img src={myphoto} alt="" className="md:w-[44%]" />
          <div className="">
            <H>
              Sumit
              <span className="font-laica"> Kumar</span>
            </H>
            <P className="mt-4">
              Hi, thanks for stopping by. I'm a full-stack designer who has
              worked on all parts of the UX process, from identifying problems
              and conducting research to crafting visually pleasing interfaces.
              I'm fussy about details and get excited about in-depth research
              and brainstorming solutions.
              <br />
              <br />
              I was studying Mechanical Engineering at IIT BHU before I decided
              to leave and explore Product Design. Since then, I've never looked
              back. Besides design, I find joy in learning new things, whether
              it's sports, gaming, or machine learning.
              <br />
              <br />
              Feel free to get in touch. I'm excited about the opportunity to
              collaborate and build something incredible together.
            </P>
          </div>
        </div>
      </div>

      {/* Container */}
      <div className="flex flex-col md:gap-32 gap-32 md:mt-40 mt-32">
        {/*  Knowing the territory */}
        <div className="flex justify-center">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              APPROACH TO LIFE
            </p>
            <div className="md:w-4/5">
              <H>
                Knowing the territory takes{" "}
                <span className="font-laica">patient</span> and{" "}
                <span className="font-laica">direct</span> observation
              </H>
            </div>
            <div className="flex md:flex-row flex-col md:gap-16 gap-8 md:w-[72%] w-full md:mt-10 mt-4 md:items-start items-center">
              <img
                src={artofcover}
                alt=""
                className="md:w-2/5 w-3/5 md:mt-0 mt-6"
              />
              <div>
                <P className="">
                  She was blocked because she was trying to repeat, in her
                  writing, things she had already heard, just as on the first
                  day he had tried to repeat things he had already decided to
                  say. She couldn't think of anything to write about Bozeman
                  because she couldn't recall anything she had heard worth
                  repeating. She was strangely unaware that she could look and
                  see freshly for herself, as she wrote, without primary regard
                  for what had been said before. The narrowing down to one brick
                  destroyed the blockage because it was so obvious she had to do
                  some original and direct seeing.
                </P>
                <P className="mt-4">
                  — Robert M. Pirsig
                  <br />
                  <span className="opacity-50 mt-1">
                    Zen and the Art of Motorcycle Maintenance
                  </span>
                </P>
              </div>
            </div>
          </div>
        </div>

        {/* Books I love */}
        <div className="flex justify-center">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              READING
            </p>

            <H>
              Books I <span className="font-laica">love</span>
            </H>
            <P className="md:w-2/5 w-full md:mt-6 mt-4 ">
              When you change the way you look at things, the things you look at
              change.
              <br />
              <br />
              Here are some books that have shaped my perspective and created a
              paradigm shift in my understanding of how the world works.
              <br />
              <span className="opacity-50 mt-4 block">
                Last updated : 23rd May, 2023
              </span>
            </P>

            {/* Books gallery */}
            <div className="flex flex-wrap md:gap-16 gap-8 md:mt-12 mt-8 md:justify-center ">
              <a
                href="https://www.readthesequences.com/"
                target="_blank"
                className="md:w-[28%] w-[42%] hover:-translate-y-2 transition-all hover:shadow-[14px_10px_60px_-15px_rgba(0,0,0,0.2)]"
              >
                <img src={rationality} alt="" />
              </a>
              <a
                href="https://typeanatomy.com/"
                target="_blank"
                className="md:w-[28%] w-[42%] hover:-translate-y-2 transition-all hover:shadow-[14px_10px_60px_-15px_rgba(0,0,0,0.2)]"
              >
                <img src={typecover} alt="" />
              </a>
              <a
                href="https://www.amazon.in/Spectrum-Ecstasy-Emotions-According-Vajrayana/dp/1590300610"
                target="_blank"
                className="md:w-[28%] w-[42%] hover:-translate-y-2 transition-all hover:shadow-[14px_10px_60px_-15px_rgba(0,0,0,0.2)]"
              >
                <img src={spectrum} alt="" />
              </a>
              <a
                href="https://www.goodreads.com/book/show/61535.The_Selfish_Gene"
                target="_blank"
                className="md:w-[28%] w-[42%] hover:-translate-y-2 transition-all hover:shadow-[14px_10px_60px_-15px_rgba(0,0,0,0.2)]"
              >
                <img src={selfish} alt="" />
              </a>
              <a
                href="https://www.goodreads.com/en/book/show/12400671"
                target="_blank"
                className="md:w-[28%] w-[42%] hover:-translate-y-2 transition-all hover:shadow-[14px_10px_60px_-15px_rgba(0,0,0,0.2)]"
              >
                <img src={free} alt="" />
              </a>
              <a
                href="https://www.goodreads.com/en/book/show/681941"
                target="_blank"
                className="md:w-[28%] w-[42%] hover:-translate-y-2 transition-all hover:shadow-[14px_10px_60px_-15px_rgba(0,0,0,0.2)]"
              >
                <img src={moral} alt="" />
              </a>
            </div>
          </div>
        </div>

        {/* Principles I live by */}
        <div className="flex justify-center">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              PROCESS
            </p>
            <div className="">
              <H>
                Principles I <span className="font-laica">live</span> by
              </H>
              <div className="flex md:flex-row flex-col justify-between md:gap-20">
                <P className="md:mt-6 mt-4 w-full">
                  Don't explain your philosophy. Embody it.
                  <br />
                  <br />
                  Principles lead to action. When you have a great set of
                  principles, the authority isn't in what you want, or what
                  you're scared of, the authority is in the principles. The
                  power of principles is less about the principles and more
                  about the process. People think that they've got them. But you
                  need to live them. Here are some principles I try to live by.
                </P>
                {/* Principles stack */}
                <div className="flex flex-col gap-8 md:mt-6 mt-12 md:w-full items-center">
                  <img src={patience} alt="" className="md:w-full w-[90%]" />
                  <img src={intensity} alt="" className="md:w-full w-[90%]" />
                  <img src={lying} alt="" className="md:w-full w-[90%]" />
                  <img src={wonder} alt="" className="md:w-full w-[90%]" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Zen mind */}
        <div className="flex justify-center">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              MEDITATION
            </p>

            <H>
              Zen mind is <span className="font-laica">beginners mind</span>
            </H>
            <P className="md:w-2/5 w-full md:mt-6 mt-4 ">
              Intensity, just for the sake of it, is meaningless.
              <br />
              <br />
              This practice is very dear to me. Meditation teaches you to relate
              to emotions without it having to mean something.
            </P>

            <img src={zen} alt="" className="md:w-[70%] mt-8 rounded-xl" />
          </div>
        </div>

        {/* Sports */}
        <div className="flex justify-center">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              SPORTS
            </p>

            <H>
              It ain't over till <span className="font-laica"> it's over</span>
            </H>
            <P className="md:w-2/5 w-full md:mt-6 mt-4 ">
              Nothing cheers me up like doing some physical activity. Into
              Running, Swimming, Fitness, Skateboarding, Squash, Cricket,
              Badminton, Table tennis and Pool. Jack of all trades, master of
              none.
            </P>
            {/* Image gallery */}
            <div className="flex md:flex-row flex-col gap-8 md:mt-8 mt-6 md:items-start items-center">
              <img src={running} alt="" className="md:w-1/4 w-[90%]" />
              <img src={snooker} alt="" className="md:w-1/4 w-[90%]" />
              <img src={swimming} alt="" className="md:w-1/4 w-[90%]" />
              <img src={skating} alt="" className="md:w-1/4 w-[90%]" />
            </div>
          </div>
        </div>

        {/* LEARNING */}
        <div className="flex justify-center">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              LEARNING
            </p>

            <H>
              Explore/<span className="font-laica">Exploit</span>
            </H>
            <P className="md:w-2/5 w-full md:mt-6 mt-4 ">
              Exploration is gathering information, and exploitation is using
              the information you have to get a known good result. <br />
              <br />
              Over the years, I've pushed myself to learn new stuff. I've given
              a shot at{" "}
              <a
                href="https://observablehq.com/@sumitrk"
                target="_blank"
                className="underline underline-offset-2"
              >
                data visualisation
              </a>
              , photography, drawing and illustration, using react, probability
              & machine learning, evolutionary psychology, rationality and lots
              more.
              <br />
              <br />
              Always excited to try something new.
            </P>
            <p className="md:mt-44 mt-24 opacity-60 font-neue text-xl leading-normal">
              If you want to build a ship, don't drum up the men to gather wood,
              divide the work, and give orders. Instead, teach them to yearn for
              the vast and endless sea.
              <br />
              <br />― Antoine de Saint-Exupéry
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
