// components
import CaseHero from "../component/CaseHero"
import H from "../component/Title"
import P from "../component/Paragraph"
import Nextcase from "../component/Nextcase"

// static
import insight_hero from "../static/insight_hero.webp"
import insight_list from "../static/insight_list.webp"
import insight_serve from "../static/insight_serve.webp"
import insight_discover from "../static/insight_discover.webp"
import insight_process from "../static/insight_process1.webp"
import insight_process2 from "../static/insight_process2.webp"
import insight_process3 from "../static/insight_process3.webp"

// gsap
import { gsap, Power2 } from "gsap"
import { useRef, useEffect } from "react"

const Insight = () => {
  let containerRef = useRef(null)

  useEffect(() => {
    const containerEl = containerRef.current

    gsap.from(containerEl, {
      opacity: 0.8,
      duration: 0.7,
      ease: Power2.easeOut,
      y: 50,
    })
  }, [])

  return (
    <div className="" ref={containerRef}>
      <CaseHero
        thumb={insight_hero}
        case="MAKE SCREENING EASIER"
        des="Based on user behaviour, Synaptic generates proprietary Insights to help VC’s discover new companies. However, users found the companies recommended via Insights to be irrelevant. I led the design to surface relevant companies."
        role={[
          "End to end design",
          "Requirements",
          "User research",
          "Prototyping",
        ]}
        team={["Dilpreet Singh - Engineer", "Shashank Jain - Engineer"]}
        company="Synaptic is an alternative data platform that helps financial firms and investors get actionable insights."
      >
        Synaptic
        <span className="font-laica font-normal"> Insights</span>
      </CaseHero>

      {/* Container */}
      <div className="flex flex-col md:gap-32 gap-32 md:mt-40 mt-32">
        {/* What are insights */}
        <div className="flex justify-center section">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              BACKGROUND
            </p>
            <H>
              What are <span className="font-laica">insights?</span>
            </H>
            <div className="flex md:flex-row flex-col gap-6 justify-between items-start">
              <div className="md:w-2/5 w-full mt-6 flex flex-col gap-8 md:order-1 order-2">
                <P>
                  To put it simply, an Insight is a pattern in data. For ex: If
                  a metric shows growth consecutively for {">"}3 months, we
                  generate a growth streak insight.
                </P>
                <P>
                  This helps VC's disocver companies that might have easily been
                  missed by current analysis tools. Some other patterns that we
                  use for generating insights are:
                </P>
                <div className="flex flex-col gap-4">
                  <P>
                    <span className="font-medium">Metric crossover:</span> If a
                    company surpasses it's competitors. Example: Figma surpassed
                    Invision in Desktop visits
                  </P>
                  <P>
                    <span className="font-medium">Outlier:</span> We use machine
                    learning to forecast a range of values within which a metric
                    should fall during next update cycle. If the value is
                    outside of the range, we generated an Outlier Insights.
                  </P>
                  <P>
                    <span className="font-medium">App ranking:</span> Twitter is
                    out of top 10 in Overall category on Play store.
                  </P>
                  <P>
                    <span className="font-medium">Employee count:</span> If
                    employee count of a company crosses a pre-defined threshold,
                    an Insight was generated.
                  </P>
                </div>
              </div>

              <div className="md:w-1/2 w-full mt-7 mx-auto md:order-2 order-1 rounded-xl">
                <img src={insight_list} alt="" className="" />
              </div>
            </div>
          </div>
        </div>

        {/* Insight serving mechanism */}
        <div className="flex justify-center section">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              RESEARCH
            </p>
            <H>
              Insight serving <span className="font-laica">mechanism</span>
            </H>
            <div className="flex md:flex-row flex-col gap-6 justify-between items-start">
              <div className="md:w-2/5 w-full mt-6 flex flex-col gap-8 md:order-1 order-2">
                <P>
                  Insights were served to users via a Feed like algorithm. They
                  were selected based on user behaviour and pushed everyday at a
                  specified time. The underlying logic depeneded on the
                  companies a user had interacted with in the past(Recently,
                  Frequently browsed), whether a given company was in a list
                  (Portfolio, Followed) or if it's similar to companies user
                  might be interested in.
                </P>
                <P>
                  The Insights pushed for a company further depeneded on the
                  metrics for which they were available. For example: An Insight
                  for App downloads was given more preference than Install
                  penetration.
                </P>
              </div>

              <div className="md:w-2/5 w-full mt-12 mx-auto md:order-2 order-1 p-8 bg-[#F5F6F5] rounded-xl">
                <img src={insight_serve} alt="" className="" />
              </div>
            </div>
          </div>
        </div>

        {/* Problems with algorithmic approach */}
        <div className="flex justify-center section">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              RESEARCH
            </p>
            <H>
              Problems with <span className="font-laica">algorithmic </span>{" "}
              approach
            </H>
            <div className="md:w-2/5 mt-6 flex flex-col">
              <P className="font-medium">Consider these user requests:</P>
              <ul className="list-disc pl-4">
                <li className="mt-2">
                  <P>
                    I want to view all {">"}6 months growth streaks in Fintech
                    sector.
                  </P>
                </li>
                <li className="mt-2">
                  <P>
                    For companies in my Portfolio, show me if there are any
                    metric crossovers
                  </P>
                </li>
                <li className="mt-2">
                  <P>Show me all fundings that are {">"}5M in Healthcare.</P>
                </li>
                <li className="mt-2">
                  <P>
                    Show me all Insights for 'Instacart' and it's competitors.
                  </P>
                </li>
                <li className="mt-2">
                  <P>
                    Show me all App ranking movements under 'top 10' in Social
                    category
                  </P>
                </li>
              </ul>
              <P className="mt-6">
                All of these use cases would require some sort of filtering on
                the feed, which was not possible in the current architecture.
                The initial assumption behind the algorithmic appraoch was that
                Insights will be consumed passively. Like social media, a VC
                would login everyday check the Insights generated for them.
                However, the data told a different story. Only 10% of the users
                clicked on an Insight (per month). Users were not even scrolling
                the feed.
                <br />
                <br />
                Apart from the engagement data, we also conducted a survey to
                guage the sentiment around feed. 80% of users said that they
                needed fine controls to filter insights.
              </P>
            </div>
          </div>
        </div>

        {/* Making it easy to discover new companies */}
        <div className="flex justify-center section">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              OUTPUT
            </p>
            <H>
              Making it easy to <span className="font-laica">discover</span> new
              companies
            </H>
            <div className="md:w-2/5 mt-6 flex flex-col">
              <P>
                The goal of insights was to surface new companies to VC's that
                they would have missed otherwise. Pushing insights to users was
                one way to achieve that. The other was to hand the controls to
                users. I added filters to let them fine tune insights. This
                would allow users to filter Insights based on Geography, Data
                source, Insight type and other parameters.
                <br />
                <br />
                Adding filters had another added benefit. Till now we were in
                the dark about the type of Insight that were actually valuable
                to the users. Adding filters would give us valuable data that'll
                help us determine the relative importance of each Insight type.
              </P>
            </div>
            <div className="p-12 bg-[#F5F6F5] md:rounded-xl mt-8 md:mx-0 -mx-8">
              <img src={insight_discover} alt="" />
            </div>
            <div className="md:w-2/5 mt-6">
              <h3 className="font-bold mt-8">BETTER NAVIGATION</h3>
              <P className="mt-3">
                Previously, clicking on an Insight opened up a modal window to
                display the details. This was not the right design pattern to
                view details. Modal is needed when an action requires user input
                or if you want to highlight a piece of information. None of
                these use cases were applicable here. Also, it took ~2-3 seconds
                to load the content during which a user couldn't perform any
                action, which was frustrating.
                <br />
                <br />
                To remedy this, we added another column to show the details.
                This allows you to maintain the context while investigating
                details at the same time.
              </P>
            </div>
          </div>
        </div>

        {/* Process */}
        <div className="flex justify-center section">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              PROCESS
            </p>
            <H>
              UI <span className="font-laica">explorations</span>
            </H>
            <div className="md:w-2/5 mt-6 flex flex-col">
              <P>
                Initially we positioned the filters at the top of the list.
                Since we already had the dropdown component in our design
                system, this was easy to implement. However, there were a lot UX
                issues with this architecture.
              </P>
              <ul className="list-disc pl-4">
                <li className="mt-2">
                  <P>
                    This pattern was not scalable. Adding another filter or
                    decreasing the screen size would require multiple rows to
                    show them.
                  </P>
                </li>
                <li className="mt-2">
                  <P>
                    It's difficult to remember the position of a specific filter
                    in a horizontal stack.
                  </P>
                </li>
                <li className="mt-2">
                  <P>Show me all fundings that are {">"}5M in Healthcare.</P>
                </li>
                <li className="mt-2">
                  <P>
                    In case of multi-select options, it's challenging to show
                    the current selection in a drop-down pattern
                  </P>
                </li>
              </ul>
            </div>
            <div className="p-12 bg-[#F5F6F5] md:rounded-xl mt-8 md:mx-0 -mx-8">
              <img src={insight_process} alt="" />
            </div>

            <P className="md:w-2/5 mt-8">
              Most importantly, the options were not visible. This shifted the
              onus of exploring the UI on the users and made the experience
              un-inviting. To entice users and show them the possibilities
              upfront, we opened up the filters related to Insights. Although
              this was better, now we had filters running down the length and
              breadth of the screen.
            </P>

            <div className="p-12 bg-[#F5F6F5] md:rounded-xl mt-8 md:mx-0 -mx-8">
              <img src={insight_process2} alt="" />
            </div>

            <P className="md:w-2/5 mt-8">
              Finally, to reduce the clutter and make the experience more
              seamless, we moved all the filters in the sidebar. Expanding all
              the filters by default was overwhelming & redundant. None of the
              workflows we identified during research needed all the filters at
              once. To solve this, we added an option to collapse the filters.
            </P>

            <div className="p-12 bg-[#F5F6F5] md:rounded-xl mt-8 md:mx-0 -mx-8">
              <img src={insight_process3} alt="" />
            </div>
          </div>
        </div>
        {/* Results */}
        <div className="flex justify-center section">
          <div className="md:w-4/5">
            <p className="text-sm font-neue opacity-30 tracking-[0.05em] mb-2">
              RESULTS
            </p>
            <H>
              Learnings from the <span className="font-laica">project</span>
            </H>
            <div className="md:w-2/5 mt-6 flex flex-col gap-3">
              <div className="flex flex-col gap-4 mt-4">
                <P>
                  <span className="font-medium">
                    Data informed decision making:
                  </span>{" "}
                  We used engagement data to highlight the dissonance between
                  how we thought users consumed insights and the actual usage.
                </P>

                <P>
                  <span className="font-medium">
                    Jobs to be done & team alignment:
                  </span>{" "}
                  Framing the problem in terms of JTBD helped align the team
                  towards a common goal - Surface relevant companies via
                  Insights.
                </P>
                <P>
                  <span className="font-medium">
                    Importance of Mental models:
                  </span>{" "}
                  The hypothesis that insights will be used passively led to the
                  creation of feed-like experience. In hindsight, this was an
                  erroneous assumption which could have been avoided.
                </P>
              </div>
            </div>
          </div>
        </div>
        {/* Next case study */}
        <Nextcase to="/urban">
          Urban <span className="font-laica">Piper</span>
        </Nextcase>
      </div>
    </div>
  )
}

export default Insight
