const HeroImage = (props) => {
  if (props.layout == "row") {
    return (
      <div className="flex justify-center md:flex-row flex-col items-center md:gap-28 gap-12 bg-[#303843] md:rounded-2xl rounded-none md:pt-24 pt-16 px-16 md:m-0 -mx-8 overflow-hidden">
        <img
          src={props.thumb}
          alt=""
          className=" md:max-w-[320px] w-[90%] translate-y-2 md:order-1 order-2"
        />
        <p className="font-beausite md:text-6xl text-4xl text-white tracking-tight leading-relaxed md:order-2 order-1">
          {props.title}
        </p>
      </div>
    )
  } else {
    return (
      <div className="flex flex-col items-center bg-[#303843] md:rounded-2xl rounded-none md:pt-20 pt-8 md:px-16 px-8 md:m-0 -mx-8 overflow-hidden">
        <div className="flex flex-col md:gap-16 gap-8 md:w-[80%] w-full">
          <p className="font-beausite md:text-6xl text-4xl text-white tracking-tight leading-relaxed">
            {props.title}
          </p>
          <img src={props.thumb} alt="" className=" w-full" />
        </div>
      </div>
    )
  }
}

export default HeroImage
